import React from "react"
import { useForm } from "react-hook-form"

import Button from "../../common/Button/Button"
import ContactAnimation from "../../common/ContactAnimation/ContactAnimation"
import Input from "../../common/Input/Input"
import Textarea from "../../common/Textarea/Textarea"
import { IFormData } from "../../core/services/email.service"
import useInterval from "../../core/hooks/useInterval"

import contactFormValidations from "./validations"
import useContactSagas from "./useConctactSagas.hook"
import useContactStore from "./useContactStore.hook"

import styles from "./ContactPage.module.scss"

// Validates the first half of an email address.
const validateText = (text: string) => {
	// NOTE: Passes RFC 5322 but not tested on google's standard.
	// eslint-disable-next-line no-useless-escape
	const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))$/
	return re.test(text) || text.length === 0
}

const messages = [
	"hi",
	"hello",
	"hola",
	"konichiwa",
	"salut",
	"yassou",
	"olá",
	"or anything, ideally",
	"but i'm using DigitalOcean",
	"and i'm not a full devops, yet ;)",
	"so as good developer",
	"the address is defaulted",
	"so don't worry",
	"and click here",
	"you can write me about anything you want",
	"like i really like your website",
	"help",
	"doubts",
	"or anything.",
	"thanks for staying this long",
	"is interesting having you here!",
]

export default function ContactPage() {
	const actions = useContactSagas()
	const store = useContactStore()
	const formFunctions = useForm<IFormData>()
	const hold = 50 // ticks to wait after message is complete before rendering next message
	const delay = 50 // tick length in mS

	const [idx, updateIter] = React.useState(0) // points to current message
	const [message, updateMessage] = React.useState(messages[idx])
	const [char, updateChar] = React.useState(messages[idx].length) // points to current char
	const [isActive, setIsActive] = React.useState(true) // disable when all messages are printed

	useInterval(
		() => {
			let newIdx = idx
			let newChar = char
			if (char - hold >= messages[idx].length) {
				newIdx += 1
				newChar = 0
			}
			if (newIdx === messages.length) {
				setIsActive(false)
			} else {
				updateMessage(messages[newIdx].slice(0, newChar))
				updateIter(newIdx)
				updateChar(newChar + 1)
			}
		},
		isActive ? delay : null
	)

	const onMouseEnter = () => setIsActive(false)
	const onMouseLeave = () => idx < messages.length && setIsActive(true)

	const onSubmit = (formData: IFormData) => {
		actions.sendEmail(formData)
	}

	return (
		<div className={styles.wrapper}>
			<div className={styles.page}>
				<div className={styles.content}>
					<h1>Contact</h1>
					<hr />
					<p>
						I'm interested in full time projects - especially ambitious or large projects. However, if you have a doubt
						or you need anything else, don't hesitate to write me to:
					</p>
					<div
						className={styles.email}
						style={validateText(message) ? {} : { color: "red" }}
						onMouseEnter={onMouseEnter}
						onMouseLeave={onMouseLeave}
					>
						<a href="mailto:hello@eliasrodeloso.dev">
							<span>{message}</span>
							<span>@eliasrodeloso.dev</span>
						</a>
					</div>
					<p>Or you can use the form below</p>
					<form className={styles.form} onSubmit={formFunctions.handleSubmit(onSubmit)}>
						<div className={styles.row}>
							<Input
								ref={formFunctions.register(contactFormValidations.name)}
								error={formFunctions.errors.name?.message}
								type="text"
								name="name"
								id="fullname"
								placeholder="Name"
							/>
							<Input
								ref={formFunctions.register(contactFormValidations.email)}
								error={formFunctions.errors.email?.message}
								type="email"
								name="email"
								id="email"
								placeholder="Email"
							/>
						</div>
						<div className={styles.row}>
							<Input
								ref={formFunctions.register(contactFormValidations.subject)}
								error={formFunctions.errors.subject?.message}
								type="text"
								name="subject"
								id="subject"
								placeholder="Subject"
							/>
						</div>
						<Textarea
							ref={formFunctions.register(contactFormValidations.message)}
							error={formFunctions.errors.message?.message}
							name="message"
							id="message"
							cols={25}
							rows={5}
							placeholder="Message"
						/>
						<Button to="" isLink={false} variant="primary" type="submit" size="large" className={styles.submit}>
							{store.isSending ? "Sending..." : store.emailSent ? "Email sent!" : "Send"}
						</Button>
					</form>
				</div>
				<div className={styles.animation}>
					<ContactAnimation />
				</div>
			</div>
		</div>
	)
}
