import React, { useEffect, useRef } from "react"
import clsx from "classnames"

import Button from "../../common/Button/Button"
import Testimonials from "../../common/Testimonials/Testimonials"

import styles from "./HomePage.module.scss"

export default function HomePage() {
	const line3Ref = useRef<HTMLParagraphElement>(null)

	useEffect(() => {
		setTimeout(() => {
			const { current: element } = line3Ref
			if (element) {
				element.classList.add(styles.highlight)
			}
		}, 16000)
	}, [])

	return (
		<div className={styles.wrapper}>
			<div className={styles.who}>
				<div className={styles.console}>node ./script.js</div>
				<div className={styles.result}>
					<p className={styles.line1}>Hello World!</p>
					<p className={styles.line2}>I'm Elias,</p>
					<p ref={line3Ref} className={clsx(styles.line3)}>
						Javascript Developer.
					</p>
				</div>
				<Button variant="primary" size="large" to="/about" isLink>
					Know More
				</Button>
			</div>
			<div className={styles.recommendations}>
				<Testimonials />
			</div>
		</div>
	)
}
