import React from "react"
import { BrowserRouter } from "react-router-dom"
import { Provider as ReduxProvider } from "react-redux"
import { Helmet, HelmetProvider } from "react-helmet-async"

import store from "../config/store.config"
import LoadingBar from "../common/LoadingBar/LoadingBar"

import Router from "../config/Routes.config"

export default function App() {
	return (
		<BrowserRouter>
			<HelmetProvider>
				<ReduxProvider store={store}>
					<Helmet>
						<meta charSet="utf-8" />
						<title>Home - Elias Rodelo</title>
					</Helmet>
					<LoadingBar />
					<Router />
				</ReduxProvider>
			</HelmetProvider>
		</BrowserRouter>
	)
}
