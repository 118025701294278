/* eslint-disable no-useless-escape */
export const emailRegEx = /^(?:(?:[a-zA-Z`~!#$%^&*\-=+;:{}'|,?\/]+(?:(?:\.(?:"(?:\\?[a-zA-Z`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)*"|[a-zA-Z`~!#$%^&*\-=+;:{}'|,?\/]+))*\.[a-zA-Z`~!#$%^&*\-=+;:{}'|,?\/]+)?)|(?:"(?:\\?[a-zA-Z`~!#$%^&*\-=+;:{}'|,?\/\.()<>\[\] @]|\\"|\\\\)+"))@(?:[a-zA-Z\d\-]+(?:\.[a-zA-Z\d\-]+)*|\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])$/g
/* eslint-enable no-useless-escape */

const contactFormValidations = {
	email: {
		required: "Email is required",
		pattern: {
			value: emailRegEx,
			message: "Email must be a valid email",
		},
	},
	name: {
		required: "Name is required",
	},
	subject: {
		required: "Subject is required",
	},
	message: {
		required: "Message is required",
	},
}

export default contactFormValidations
