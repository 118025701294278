import React from "react"
import { Outlet } from "react-router-dom"

import Particles from "../common/Particles/Particles"
import NavBar from "../common/NavBar/NavBar"

import styles from "./MainLayout.module.scss"

export default function MainLayout() {
	return (
		<div className={styles.layout}>
			<Particles className={styles.particles} />
			<NavBar />
			<div className={styles.content}>
				<Outlet />
			</div>
		</div>
	)
}
