import React from "react"

export default function useInterval(callback: () => void, delay: number | null) {
	const savedCallback = React.useRef<() => void>(callback)

	React.useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	React.useEffect(() => {
		if (delay) {
			const id = setInterval(() => {
				savedCallback.current()
			}, delay)
			return () => clearInterval(id)
		}
		return () => {} // pass linter
	}, [delay])
}
