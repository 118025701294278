import createReducer from "../factories/createReducer.factory"
import emailActionTypes from "../action-types/email.actionTypes"

export interface IEmailReducer {
	emailSent: boolean | null
}

const initialState: IEmailReducer = {
	emailSent: null,
}

export function emailSent(state: IEmailReducer) {
	return {
		...state,
		emailSent: true,
	}
}

export default createReducer(initialState, {
	[emailActionTypes.SEND_EMAIL_SUCCESS]: emailSent,
})
