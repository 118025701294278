import React from "react"
import { Link, useLocation } from "react-router-dom"
import clsx from "classnames"

import { ReactComponent as Logo } from "../../assets/icons/favicon.svg"
import { ReactComponent as HomeIcon } from "../../assets/icons/home.svg"
import { ReactComponent as GitHubIcon } from "../../assets/icons/github.svg"
import { ReactComponent as LinkedInIcon } from "../../assets/icons/linkedin.svg"
import { ReactComponent as MailIcon } from "../../assets/icons/email.svg"
import { ReactComponent as AboutIcon } from "../../assets/icons/about.svg"
// import { ReactComponent as StatsIcon } from "../../assets/icons/skills.svg"
import { ReactComponent as ContactIcon } from "../../assets/icons/contact.svg"

import styles from "./NavBar.module.scss"

export default function NavBar() {
	const location = useLocation()

	return (
		<div className={styles.navBar}>
			<a href="/" className={styles.logo}>
				<Logo className={styles.logoImg} />
			</a>

			<nav className={styles.nav}>
				<Link to="/" className={clsx(styles.link, location.pathname === "/" && styles.active)}>
					<HomeIcon className={styles.linkIcon} />
					<span className={styles.linkDesc}>Home</span>
				</Link>
				<Link to="/about" className={clsx(styles.link, location.pathname === "/about" && styles.active)}>
					<AboutIcon className={styles.linkIcon} />
					<span className={styles.linkDesc}>About</span>
				</Link>
				{/* <Link to="/stats" className={clsx(styles.link, location.pathname === "/stats" && styles.active)}>
					<StatsIcon className={styles.linkIcon} />
					<span className={styles.linkDesc}>Stats</span>
				</Link> */}
				<Link to="/contact" className={clsx(styles.link, location.pathname === "/contact" && styles.active)}>
					<ContactIcon className={styles.linkIcon} />
					<span className={styles.linkDesc}>Contact</span>
				</Link>
			</nav>

			<ul className={styles.social}>
				<li>
					<a className={styles.socialLink} href="https://github.com/eliasrodeloso">
						<GitHubIcon className={styles.socialLinkIcon} />
					</a>
				</li>
				<li>
					<a className={styles.socialLink} href="https://linkedin.com/in/eliasrodeloso">
						<LinkedInIcon className={styles.socialLinkIcon} />
					</a>
				</li>
				<li>
					<a className={styles.socialLink} href="mailto:hello@eliasrodeloso.dev">
						<MailIcon className={styles.socialLinkIcon} />
					</a>
				</li>
			</ul>
		</div>
	)
}
