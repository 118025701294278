import { combineReducers } from "redux"

import loadingReducer from "./loading.reducer"
import errorReducer from "./error.reducer"
import emailReducer, { IEmailReducer } from "./email.reducer"

export interface IReduxState {
	loadingStore: object
	errorStore: object
	emailStore: IEmailReducer
}

export default combineReducers({
	loadingStore: loadingReducer,
	errorStore: errorReducer,
	emailStore: emailReducer,
})
