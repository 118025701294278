import { useSelector } from "react-redux"

import emailSendingStateSelector from "../../core/selectors/email.selectors"
import loadingSelector from "../../core/selectors/loading.selector"

export default function useContactStore() {
	return {
		emailSent: useSelector(emailSendingStateSelector),
		isSending: useSelector(loadingSelector(["SEND_EMAIL"])),
	}
}
