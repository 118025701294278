import React, { useEffect, useRef, useState } from "react"
import clsx from "classnames"
import Carousel from "react-slick"

import { ReactComponent as QuotesIcon } from "../../assets/icons/quotes.svg"

import styles from "./Testimonials.module.scss"
import testimonials from "./testimonials.json"

interface ITestimonial {
	recommendation: string
	author: string
	role: string
	authorAvatar: string
}

export default function Testimonials() {
	const promisedTestimonials = useRef<Array<ITestimonial>>([])
	const [readyTestimonials, setReadyTestimonials] = useState(false)

	useEffect(() => {
		testimonials.forEach((item: ITestimonial, index) => {
			import(`../../assets/images/${item.authorAvatar}`).then((img) => {
				promisedTestimonials.current.push({
					...item,
					authorAvatar: img.default,
				})
				if (index + 1 === testimonials.length) {
					setReadyTestimonials(true)
				}
			})
		})
	}, [])

	return (
		<div className={clsx(styles.testimonials, "testimonials")}>
			{readyTestimonials && (
				<Carousel dots={false} speed={10000} infinite>
					{promisedTestimonials.current.map((testimonial: ITestimonial) => (
						<div
							className={styles.slide}
							key={`${testimonial.recommendation.slice(0, 10)},${testimonial.author},${testimonial.authorAvatar}`}
						>
							<QuotesIcon className={styles.quotes} />
							<div className={styles.avatar}>
								<img src={testimonial.authorAvatar} alt={testimonial.author} className={styles.avatarImg} />
							</div>
							<div className={styles.content}>
								<p className={styles.recommendation}>{testimonial.recommendation}</p>
								<p className={styles.name}>{testimonial.author}</p>
								<p className={styles.designation}>{testimonial.role}</p>
							</div>
						</div>
					))}
				</Carousel>
			)}
		</div>
	)
}
