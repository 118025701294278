import axios from "axios"

import requestInterceptor from "../core/interceptors/request.interceptor"
import responseInterceptor from "../core/interceptors/response.interceptor"

import env from "./constants/environment.constants"

const onErrorInterceptor = (error: Error) => Promise.reject(error)

/**
 * Axios client to use the Alegra API services
 */
const axiosEmailClient = axios.create({
	baseURL: env.API_URL,
	headers: {
		"Content-Type": "application/json",
	},
})

axiosEmailClient.interceptors.request.use(requestInterceptor, onErrorInterceptor)
axiosEmailClient.interceptors.response.use(responseInterceptor)

export default axiosEmailClient
