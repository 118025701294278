import { AxiosRequestConfig } from "axios"

import currentEnv from "../../config/constants/environment.constants"

/* eslint-disable camelcase */
export default function emailRequestInterceptor(request: AxiosRequestConfig): AxiosRequestConfig {
	return {
		...request,
		data: JSON.stringify({
			service_id: currentEnv.EMAIL_SERVICE_ID,
			template_id: currentEnv.EMAIL_TEMPLATE_ID,
			user_id: currentEnv.EMAIL_USER_ID,
			template_params: request.data.formData,
		}),
	}
}
/* eslint-enable camelcase */
