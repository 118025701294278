import { AxiosResponse } from "axios"

import axiosEmailClient from "../../config/axios.config"

export interface IFormData {
	email: string
	name: string
	subject: string
	message: string
}

export default class EmailService {
	static sendEmail(formData: IFormData): Promise<AxiosResponse<Record<string, unknown>>> {
		return axiosEmailClient.post("/email/send", { formData: formData })
	}
}
