import { AxiosResponse } from "axios"
import { call, put, takeLatest } from "redux-saga/effects"

import EmailService, { IFormData } from "../services/email.service"
import actionFactory from "../factories/action.factory"
import emailActionTypes from "../action-types/email.actionTypes"

function* sendEmail(data: { type: typeof emailActionTypes.SEND_EMAIL_REQUEST; payload: IFormData }) {
	try {
		const response: AxiosResponse<Record<string, unknown>> = yield call(EmailService.sendEmail, data.payload)
		yield put(actionFactory(emailActionTypes.SEND_EMAIL_SUCCESS, response))
	} catch (error) {
		yield put(actionFactory(emailActionTypes.SEND_EMAIL_FAILURE, error))
	}
}

export default function* watchSendEmail() {
	yield takeLatest(emailActionTypes.SEND_EMAIL_REQUEST, sendEmail)
}
