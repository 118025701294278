import React from "react"

import Button from "../../common/Button/Button"
import avatar from "../../assets/images/profile.jpeg"

import styles from "./AboutPage.module.scss"

export default function AboutPage() {
	return (
		<div className={styles.about}>
			<div className={styles.aboutWrapper}>
				<div className={styles.asideContainer}>
					<div className={styles.information}>
						<img src={avatar} alt="Elias' avatar" className={styles.avatar} />
					</div>
					<Button to="/contact" variant="primary" size="large" isLink>
						Contact Me
					</Button>
				</div>
				<div className={styles.content}>
					<h1>About Me</h1>
					<hr />
					<p>
						Long story short, I am <b>Elias</b>!, I like getting things done. I am a Fullstack Javascript Developer
						based on Medellín Colombia. I have over 5 years of experience building products and experiences and I
						specialized in custom enterprise-grade Javascript applications.
					</p>
					<p>
						Let me tell you that I never thought I'd be a developer, but I've been writing code for 5 years now. At the
						beginning I started with a family project, to create a virtual store, where we could sell the products that
						we sold on our city to the whole country, it was a rewarding experience because although we had no idea how
						we were going to do it, with the time we learned how powerful it could be, since then I have dedicated
						myself to continue getting that feeling of learning and gratification in what I do.
					</p>
					<p>
						At that time of my career I started developing features and templates for CMS like Prestashop for ecommerce
						then I continued specializing in custom Frontend development with Javascript, using tools like React and
						Vue.
					</p>
					<p>
						At this point in my career I am aiming to specialize in being a Fullstack Javascript developer. My
						specialties acquired during my career include rapid learning of new technologies, problem solving, focus on
						specific product needs, and rapid adaptation to change. So far I have learned Javascript, Python, Go, Java,
						MongoDB, SQLServer, NodeJS, Express, React, React Native, Vue JS, Webpack, CSS3, CSS Preprocessors, HTML5,
						Webpack, Typescript, JSS, Redux, Redux Sagas, a little DevsOps with AWS and Jenkins. Lately I have been
						learning about ORMs, like Sequelize, more about Node, Serverless, Lambdas functions, and PostgreSQL.
					</p>
					<h4>I like</h4>
					<ul>
						<li>Getting things done</li>
						<li>Rock (From 80s to 20s, pretty much all rock)</li>
						<li>Travelling</li>
						<li>Reading Manga</li>
						<li>Watching Anime, and normal series too!</li>
						<li>Driving (Motorcycle)</li>
						<li>Landscaping</li>
					</ul>
				</div>
			</div>
		</div>
	)
}
