const envConstants = {
	development: {
		NODE_ENV: process.env.NODE_ENV,
		API_URL: "https://api.emailjs.com/api/v1.0",
		EMAIL_SERVICE_ID: "eliasrodeloso.dev",
		EMAIL_TEMPLATE_ID: "template_txbtyh8",
		EMAIL_USER_ID: "user_6PTfL3EP9YtimRUr8gHxi",
	},
	production: {
		NODE_ENV: process.env.NODE_ENV,
		API_URL: "https://api.emailjs.com/api/v1.0",
		EMAIL_SERVICE_ID: "eliasrodeloso.dev",
		EMAIL_TEMPLATE_ID: "template_txbtyh8",
		EMAIL_USER_ID: "user_6PTfL3EP9YtimRUr8gHxi",
	},
}

// @ts-ignore
const currentEnv = envConstants[process.env.NODE_ENV] ? envConstants[process.env.NODE_ENV] : envConstants.development

export default currentEnv
