import React from "react"
import { createRoot } from "react-dom/client"

import App from "./app/App"
import "./config/styles/styles.config.scss"

const container = document.getElementById("root") ?? document.createElement("div")
const root = createRoot(container)

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
