import React, { useEffect } from "react"
import { tsParticles } from "tsparticles"
import { IOptions } from "tsparticles/dist/Interfaces/Options/IOptions"
import { RecursivePartial } from "tsparticles/dist/Types/RecursivePartial"

/* eslint-disable camelcase */
const option: RecursivePartial<IOptions> = {
	particles: {
		number: {
			value: 355,
			density: {
				enable: true,
				value_area: 789.1476416322727,
			},
		},
		color: {
			value: "#ffffff",
		},
		shape: {
			type: "circle",
			stroke: {
				width: 0,
				color: "#000000",
			},
			polygon: {
				nb_sides: 5,
			},
		},
		opacity: {
			value: 0.25,
			random: false,
			anim: {
				enable: true,
				speed: 0.5,
				opacity_min: 0,
				sync: false,
			},
		},
		size: {
			value: 2,
			random: true,
			anim: {
				enable: true,
				speed: 2,
				size_min: 0,
				sync: false,
			},
		},
		line_linked: {
			enable: false,
			distance: 150,
			color: "#ffffff",
			opacity: 0.4,
			width: 1,
		},
		move: {
			enable: true,
			speed: 0.5,
			direction: "none",
			random: true,
			straight: false,
			out_mode: "out",
			bounce: false,
			attract: {
				enable: false,
				rotateX: 600,
				rotateY: 1200,
			},
		},
	},
	interactivity: {
		detect_on: "window",
		events: {
			onhover: {
				enable: true,
				mode: "bubble",
			},
			onclick: {
				enable: true,
				mode: "repulse",
			},
			resize: true,
		},
		modes: {
			grab: {
				distance: 400,
				line_linked: {
					opacity: 1,
				},
			},
			bubble: {
				distance: 83.91608391608392,
				size: 1,
				duration: 3,
				opacity: 1,
			},
			repulse: {
				distance: 100,
				duration: 0.5,
			},
			connect: {
				distance: 100,
				radius: 60,
				lineLinked: {
					opacity: 0.4,
				},
			},
			remove: {
				particles_nb: 2,
			},
		},
	},
	retina_detect: true,
}
/* eslint-enable camelcase */

interface ParticlesProps {
	className?: string
}

export default function Particles(props: ParticlesProps) {
	useEffect(() => {
		tsParticles.load("particles", option)
	}, [])

	return <div id="particles" {...props} />
}
