import { useDispatch } from "react-redux"

import actionFactory from "../../core/factories/action.factory"
import emailActionTypes from "../../core/action-types/email.actionTypes"
import { IFormData } from "../../core/services/email.service"

export default function useContactSagas() {
	const dispatch = useDispatch()
	return {
		sendEmail: (formData: IFormData) => dispatch(actionFactory(emailActionTypes.SEND_EMAIL_REQUEST, formData)),
	}
}
