import React from "react"
import { Routes, Route } from "react-router-dom"

import MainLayout from "../layouts/Main.layout"

import ContactPage from "../pages/contact/Contact.page"
import AboutPage from "../pages/about/About.page"
import HomePage from "../pages/home/Home.page"

export default function Router() {
	return (
		<Routes>
			<Route path="/" element={<MainLayout />}>
				<Route path="/" element={<HomePage />} />
				<Route path="/about" element={<AboutPage />} />
				<Route path="/contact" element={<ContactPage />} />
			</Route>
		</Routes>
	)
}
